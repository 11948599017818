import React from 'react';

// Components
import {Row, Col, Image, Card} from 'react-bootstrap';

// CSS & Img
import logo from "../assets/img/GBCVale.png";


class Footer extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          
        }
    }
    
    render() {
      var today = new Date(),
      year = today.getFullYear()
      return (
        <>
        <Row className="footer">
          <Card className="primary-card">
              <Col xs="12" className="logo-gbc">
                <h6>Developed by</h6>
                <Image src={logo} width="80" alt="logo_gbc_vale" />
              </Col>
              <Col xs="12" className="logo-gbc">
                <h6>Copyright © {year} | <a href="https://globalbridgeconnections.com">Global Bridge Connections.</a></h6>
              </Col>
          </Card>
        </Row>
        {/* <Row className="logo-container">
        </Row> */}
        </>
      );
    }
}


export default Footer; 