// Library
import React from 'react';
import {Row, Col, Image, Card} from 'react-bootstrap';
import { Button } from "@mui/material";
import empty from "is-empty";
import { history } from '../helpers/History';
import { connect } from "redux-zero/react";
import actions from '../actions';

// Components

// CSS & Img
import clientLogo from "../assets/img/logo-global-color.png";
import clientGBC from "../assets/img/logo_gbc.png";
import clientBANEXCOIN from "../assets/img/logo_banex.png";
import clientELECTRON from "../assets/img/logo_electron.png";
import { FaSignOutAlt } from 'react-icons/fa';

class Header extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          client  : {},
        }
    }

    processSignOut(){
      this.props.AuthActions({ action: 'clearSession' });
    }
    
    render() {
      const { user } = this.props;
      let logoHeader = clientGBC;
      if(!empty(user)){

          if(user.gbcValeMerchantByGbcValeMerchantId.companyName == 'GLOBAL BRIDGE CONNECTIONS'){
            logoHeader = clientGBC;
          }

          if(user.gbcValeMerchantByGbcValeMerchantId.companyName == 'BANEXCOIN'){
            logoHeader = clientBANEXCOIN;
          }

          if(user.gbcValeMerchantByGbcValeMerchantId.companyName == 'ELECTRON MOTORS'){
            logoHeader = clientELECTRON;
          }

      }

      return (
        <>
        <Row className="header-container">
          <Card className="secondary-card">
            <Col xs={8} sm={9} className="logo-client-container">
                <Image src={logoHeader} alt="client_logo" width="160" height="auto" className="client-logo" />
            </Col>
            {user!=null && !empty(user) ? 
            <Col xs={4} sm={3} className="text-center">
              <Button variant="outlined" className="btn-signout" onClick={()=>{this.processSignOut()}}><FaSignOutAlt className="copy-icon mr-1" />Cerrar Sesión</Button>
            </Col> : ''}
          </Card>
        </Row>
        </>
      );
    }
}

const mapToProps = ({ 
  user
}) => ({ 
  user
});
const connectedHeader = connect(mapToProps, actions)(Header);
export { connectedHeader as Header };