import React, { Component } from "react";

// Components Bootstrap & Material
import { Container, Row, Card, Col, Image } from "react-bootstrap";
import { Switch } from "@mui/material";
import { toast } from "react-toastify";

// CSS Img & Icons
import mastercard from "../../assets/img/mastercard.png";
import { FaRegCopy } from "react-icons/fa";
import empty from "is-empty";

class TemplateCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      show: false,
    };
  }

  msgToastToCopyClipboard() {
    toast.success("Copiado al portapapeles", {
      position: "top-center",
      autoClose: 5000,
      closeOnClick: true,
      pauseOnHover: true,
    });
  }

  render() {
    const { show } = this.state;
    const { data } = this.props;
    let user,
      full_name,
      card = {};

    if (!empty(data)) {
      user = data.client;
      card = data.card;

      full_name = `${user.first_name} ${
        user.second_name ? user.second_name : ""
      } ${user.first_last_name} ${
        user.second_last_name ? user.second_last_name : ""
      }`;
    }

    return (
      <Container fluid className="card-container">
        <Row className="justify-content-md-center">
          <Card style={{ width: "25rem" }}>
            <Card.Body>
              <Card.Subtitle className="mb-2 text-muted text-right">
                <h4>Ver detalle CVV</h4>
                <Switch
                  checked={show}
                  onChange={() => {
                    this.setState({ show: !show });
                  }}
                  name="show"
                  color="primary"
                  inputProps={{ "aria-label": "primary checkbox" }}
                />
              </Card.Subtitle>
              <Card.Text>
                <Row className="justify-content-md-center number-card">
                  <Col xs="8" className="d-flex">
                    {!empty(data) && show ? (
                      <h4>
                        {card.number_card.toString().match(/.{4}/g).join(" ")}
                      </h4>
                    ) : (
                      <h2 style={{ color: "white", fontWeight: 500 }}>
                        **** **** **** ****
                      </h2>
                    )}
                    {!empty(data) && show ? (
                      <FaRegCopy
                        className="copy-icon"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            card.number_card.toString()
                          );
                          this.msgToastToCopyClipboard();
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col xs="4" className="d-flex">
                    <h4>CVV {!empty(data) && show ? card.cvv2 : "***"}</h4>
                    {!empty(data) && show ? (
                      <FaRegCopy
                        className="copy-icon"
                        onClick={() => {
                          navigator.clipboard.writeText(card.cvv2);
                          this.msgToastToCopyClipboard();
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row className="top-info-card">
                  <Col xs="5" className="d-flex">
                    <h4>Card Holder</h4>{" "}
                    {!empty(data) ? (
                      <FaRegCopy
                        className="copy-icon"
                        onClick={() => {
                          navigator.clipboard.writeText(full_name);
                          this.msgToastToCopyClipboard();
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col xs="5" className="d-flex">
                    <h4>Expira</h4>
                    {!empty(data) && show ? (
                      <FaRegCopy
                        className="copy-icon"
                        onClick={() => {
                          navigator.clipboard.writeText(
                            card.date_expiration
                              .toString()
                              .match(/.{2}/g)
                              .join(" ")
                              .split(" ")[1] +
                              "/20" +
                              card.date_expiration
                                .toString()
                                .match(/.{2}/g)
                                .join(" ")
                                .split(" ")[0]
                          );
                          this.msgToastToCopyClipboard();
                        }}
                      />
                    ) : (
                      ""
                    )}
                  </Col>
                </Row>
                <Row className="info-card">
                  <Col xs="5">
                    <h4>{full_name}</h4>
                  </Col>
                  <Col xs="4">
                    {!empty(data) && show ? (
                      <h4>
                        {
                          card.date_expiration
                            .toString()
                            .match(/.{2}/g)
                            .join(" ")
                            .split(" ")[1]
                        }{" "}
                        / 20
                        {
                          card.date_expiration
                            .toString()
                            .match(/.{2}/g)
                            .join(" ")
                            .split(" ")[0]
                        }
                      </h4>
                    ) : (
                      <h4 style={{ color: "white", fontWeight: 500 }}>
                        ** / ****
                      </h4>
                    )}
                  </Col>
                  <Col xs="3">
                    <Image
                      src={mastercard}
                      alt="card_logo"
                      width="50"
                      height="35"
                      className="card-logo mr-4"
                    />
                  </Col>
                </Row>
              </Card.Text>
            </Card.Body>
          </Card>
        </Row>
      </Container>
    );
  }
}

export default TemplateCard;
