const DEFAULT = {
  loading: 0,
  loadingMsg: '',
  user: {},
  data: {},
  step: 0,
  totalResults: 0,
  token: '',
  status: '',
  message: ''
};
export default DEFAULT;
