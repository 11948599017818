// Library
import React from 'react';
import { Row, Col, Form, Container, Card } from 'react-bootstrap';
import empty from 'is-empty'
import ReactCodeInput from 'react-verification-code-input';
import { connect } from "redux-zero/react";
import actions from '../actions';

class FirstStep extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          data: {}
        }
    }
    
    render() {
      const { user } = this.props;
      return (
        <>
            {(!empty(user)) ?
            <Container fluid className="firststep">
                <Row className="justify-content-md-center">
                  <Form>
                    <Card>
                        <Card.Body>
                            <Card.Subtitle className="mb-2 text-muted text-center">
                              Hemos enviado un código de verificación al correo electrónico {user.clientEmail.replace(/^(.{3})[^@]+/, "$1***")}
                            </Card.Subtitle>
                            <Card.Text>
                              <Row className="justify-content-md-center input-code mt-4">
                                  <Col xs="12" className="d-flex">
                                     <ReactCodeInput className={'confirmation-code-box'} autoFocus={true} fields={6} onComplete={(code)=>{
                                      // traer info del localstorage
                                      const gbcValeCardLocalStorage = localStorage.getItem('gbcValeCard');
                                      const clientData =  JSON.parse(gbcValeCardLocalStorage);
                                      const params = {id:clientData.id, timestamp:clientData.timestamp, code, type:1}
                                      this.props.AuthActions({ action: 'confirmData', params : {params} });
                                     }}/>
                                  </Col>
                              </Row>
                            </Card.Text>
                        </Card.Body>
                        <Row className="request-code">
                          <a href="#" onClick={(e) => {
                            e.preventDefault();
                            e.stopPropagation();
                            // traer info del localstorage
                            const gbcValeCardLocalStorage = localStorage.getItem('gbcValeCard');
                            const clientData =  JSON.parse(gbcValeCardLocalStorage);
                            const params = {id:clientData.id, timestamp:clientData.timestamp}
                            this.props.AuthActions({ action: 'processCard', params : {params} });

                          }} >Solicitar de nuevo el código de verificación</a>
                        </Row>
                    </Card>
                  </Form>
                </Row>
            </Container> : null }
        </>
      )
    }
}

const mapToProps = ({ 
  user,
  loading,
  loadingMsg,
  location
}) => ({ 
  user,
  loading,
  loadingMsg,
  location
});
const connectedFirstStep = connect(mapToProps, actions)(FirstStep);
export { connectedFirstStep as FirstStep };