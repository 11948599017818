import LoadingActions from "./LoadingActions";
import AuthService from "../services/AuthService";
import { history } from "../helpers/History";
import ErrorActions from "./ErrorActions";
import { toast } from "react-toastify";

class AuthActions {
  static redirect(key) {
    let route = "";

    if (key == "EMPTY") {
      route = "/";
    }

    if (route != "") {
      history.push(route);
      window.location.reload(true);
    }
  }

  static handler(store, params) {
    return new AuthActions[params.action](store, params.params);
  }

  static clearSession(store) {
    store.setState({
      loading: LoadingActions.show(store),
    });

    let redirect = "EMPTY";

    localStorage.removeItem("gbcValeCard");

    store.setState({
      loading: 0,
      loadingMsg: "",
      user: {},
      step: 0,
      status: "logout",
      location: "",
      loading: LoadingActions.hide(store),
    });

    toast.success("Sesión finalizada con éxito", {
      position: toast.POSITION.TOP_CENTER,
    });

    AuthActions.redirect(redirect);
  }

  static async processCard(store, { params } = { params }) {
    let user = {};
    let status = "";

    store.setState({
      loading: LoadingActions.show(store),
    });

    try {
      let request = await AuthService.processCard({ params });
      user = request.data.client;
      status = "Verified";
      localStorage.setItem(
        "gbcValeCard",
        JSON.stringify({ id: user.id, timestamp: params.timestamp })
      );
    } catch (error) {
      ErrorActions.handlerError(store, error);
    }

    store.setState({
      user,
      status,
      loading: LoadingActions.hide(store),
    });
  }

  static async confirmData(store, { params } = { params }) {
    let data = {};
    let step = 0;

    store.setState({
      loading: LoadingActions.show(store),
    });

    try {
      data = await AuthService.confirmData({ params });
      step = 1;
      const { timestamp, code, type } = params;
      localStorage.setItem(
        "gbcValeCard",
        JSON.stringify({
          id: data.data.client.id,
          timestamp,
          code,
          type,
        })
      );
    } catch (error) {
      ErrorActions.handlerError(store, error);
    }

    store.setState({
      data,
      step,
      loading: LoadingActions.hide(store),
    });
  }
}

export default AuthActions;
