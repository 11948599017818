// Library
import React from 'react';
import { Container, Stack } from 'react-bootstrap';

import androidStoreImg from '../../assets/img/google_play.png';
import appStoreImg from '../../assets/img/app_store.png';

export function DownloadApp() {
  function openStore(url) {
    window.open(url, '_blank');
  }
  return (
    <div style={{ width: '100%', marginTop: 24 }}>
      <Stack direction="horizontal" gap={2} style={{ flexWrap: 'wrap', justifyContent: 'center' }}>
        <img
          style={{ cursor: 'pointer', height: 70 }}
          src={androidStoreImg}
          onClick={() =>
            openStore(
              'https://play.google.com/store/apps/details?id=pe.com.gbc.vale.app.mobile&hl=en-US&ah=IhizmVDu0RPEB2jmLsrvtbxHYrY'
            )
          }
        />
        <img
          style={{ cursor: 'pointer', height: 48 }}
          src={appStoreImg}
          onClick={() => openStore('https://apps.apple.com/pe/app/gbc-vale/id6468603249?l=en-GB')}
        />
      </Stack>
    </div>
  );
}
