// Library
import React from "react";
import { Row } from "react-bootstrap";
import empty from "is-empty";
import queryString from "query-string";
import { connect } from "redux-zero/react";
import actions from "../actions";

// Components
import { FirstStep } from "../components/FirstStep";
import { SecondStep } from "../components/SecondStep";

class Main extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: {},
      card: {},
      movimientos: [],
      soles: 0,
      dolares: 0,
    };
  }

  componentWillMount(props) {
    const { location, match } = this.props;

    if (!empty(location)) {
      const id = match.params.id;
      const queryData = queryString.parse(location.search);
      const timestamp = parseInt(queryData.t);
      const params = { id, timestamp };
      this.props.AuthActions({ action: "processCard", params: { params } });
    }
  }

  render() {
    const { step } = this.props;
    return (
      <Row>
        {step === 0 ? <FirstStep {...this.props} /> : ""}
        {step === 1 ? <SecondStep {...this.props} /> : ""}
      </Row>
    );
  }
}

const mapToProps = ({ step, data }) => ({
  step,
  data,
});
const connectedMain = connect(mapToProps, actions)(Main);
export { connectedMain as Main };
