import empty from "is-empty";
import axios from "axios";
import config from "config";
import "regenerator-runtime/runtime";

export default class HandlerBackend {
  static sendRequest = async (
    { method, data, path } = { method, data, path }
  ) => {
    let headers = {
      "Content-Type": "application/json",
    };

    if (!empty(localStorage.getItem("token"))) {
      headers.authorization = "bearer " + localStorage.getItem("token");
    }

    return await axios({
      url: `${config.apiUrl + config.apiPathVale + path}`,
      method,
      headers,
      data,
      timeout: 30000,
    });
  };

  static sendRequestV2 = async (
    { method, data, path } = { method, data, path }
  ) => {
    let headers = {
      "Content-Type": "application/json",
    };

    if (!empty(localStorage.getItem("token"))) {
      headers.authorization = "bearer " + localStorage.getItem("token");
    }

    return await axios({
      url: `${config.apiUrlV2 + config.apiPathValeV2 + path}`,
      method,
      headers,
      data,
      timeout: 30000,
    });
  };
}
