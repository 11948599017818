class LoadingActions {

    static handler(store, params){
        return new LoadingActions[params.action](store, params.params);
    }

    static setLoadingShow(store, {params}){
        store.setState({
            loading: LoadingActions.show(store),
            loadingMsg : params
        })
    }

    static setLoadingHide(store, params){
        store.setState({
            loading: LoadingActions.hide(store),
        })
    }

    static show(store) {
        return store.getState().loading + 1
    }

    static hide(store){
        return ((store.getState().loading - 1) <= 0) ? 0 : store.getState().loading - 1
    }

}

export default LoadingActions;

