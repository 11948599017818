import { toast } from "react-toastify";

class ErrorActions {
  static handler(store, params) {
    return new ErrorActions[params.action](store, params.params);
  }

  static handlerError(store, error) {
    let message = "Error desconocido contactar con soporte tecnico";

    if (error.status == 400) {
      message = error.data.message;
    }
    store.setState({
      status: error.status,
    });
    toast.error(message, { position: toast.POSITION.TOP_CENTER });
  }

  static handlerErrorMessage(store, error) {
    let message =
      "Esperamos que hayas disfrutado las fiestas. Nosotros seguiremos ayudándote a cumplir tus deseos, para cualquier consulta no dudes en comunicarte al correo";

    store.setState({
      status: error.status,
      data: error.data.data,
      message,
    });
  }
}

export default ErrorActions;

// const useHandleResponse = () => {
//     const { enqueueSnackbar } = useSnackbar();

//     const handleResponse = response => {
//       return response.text().then(text => {
//         const data = text && JSON.parse(text);
//         if (!response.ok) {
//           if ([401, 403].indexOf(response.status) !== -1) {
//             authenticationService.logout();
//             enqueueSnackbar('User Unauthorized', {
//               variant: 'error',
//             });
//           }

//           const error = (data && data.message) || response.statusText;
//           return Promise.reject(error);
//         }

//         return data;
//       });
//     };

//     return handleResponse;
//   }
