// Library
import React from "react";
import { Row, Col, Container, Card } from "react-bootstrap";
import empty from "is-empty";
import { connect } from "redux-zero/react";
import actions from "../actions";

// Components
import TemplateCard from "./Card";

class SecondStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount(props) {
    const { data } = this.props;
  }

  render() {
    const { data } = this.props;
    let client,
      card,
      movimientos,
      soles,
      dolares = "";
    if (!empty(data)) {
      client = data.data.client;
      card = data.data.card;
      movimientos = data.data.card.MOVIMIENTOS;
      soles = card.SALDO;
      dolares = soles * 0.25;
    }
    return (
      <>
        <Row className="secondstep-container">
          <div className="m-auto">
            <TemplateCard data={data} {...this.props} />
            <Container fluid className="balance-container">
              <Row className="justify-content-center">
                <Card style={{ width: "22rem" }}>
                  <Card.Body>
                    <Card.Subtitle className="mb-2 text-muted text-left">
                      <h4>Saldo Disponible</h4>
                    </Card.Subtitle>
                    <Card.Text>
                      <Row className="justify-content-center balance-amount">
                        <Col xs="4" className="d-flex">
                          <h6 className="mr-2">S/</h6>
                          <h5>
                            {empty(data)
                              ? 0.0
                              : soles.toFixed(2).toString().replace(".", ",")}
                          </h5>
                        </Col>
                        <Col xs="1">
                          <h6>=</h6>
                        </Col>
                        <Col xs="5" className="d-flex">
                          <h6 className="mr-2">USD$/</h6>
                          <h5>
                            {empty(data)
                              ? 0.0
                              : dolares.toFixed(2).toString().replace(".", ",")}
                          </h5>
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Row>
            </Container>
            <Container fluid className="balance-container">
              <Row className="justify-content-md-center">
                <Card style={{ width: "22rem" }}>
                  <Card.Body>
                    <Row className="d-block container-last-tx">
                      <Card.Subtitle className="mb-2 text-muted text-left">
                        <h4>Últimos movimientos</h4>
                      </Card.Subtitle>
                      {empty(card) ? (
                        <>
                          <Card.Text>
                            <Row className="justify-content-center balance-amount">
                              <Col xs={12} className="field-icon">
                                <p>No posee transacciones disponibles</p>
                              </Col>
                            </Row>
                          </Card.Text>
                        </>
                      ) : (
                        movimientos.map((m) => {
                          let amount = (Number(m.MONTO) / 100)
                            .toFixed(2)
                            .toString()
                            .replace(".", ",");
                          let dateTx =
                            m.FECHA.substring(6, 8) +
                            "/" +
                            m.FECHA.substring(4, 6) +
                            "/" +
                            m.FECHA.substring(0, 4);
                          return (
                            <>
                              <Card.Text>
                                <Row className="justify-content-center balance-amount">
                                  <Col xs={8} className="">
                                    <Row>
                                      <h6>{dateTx}</h6>
                                    </Row>
                                    <Row>
                                      <h6 className="mr-2">{m.COMERCIO}</h6>
                                    </Row>
                                  </Col>
                                  <Col xs={4} className="d-flex price">
                                    <p className="mr-2">S/</p>
                                    <h6>{amount}</h6>
                                  </Col>
                                </Row>
                              </Card.Text>
                              <hr />
                            </>
                          );
                        })
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Row>
            </Container>
          </div>
        </Row>
      </>
    );
  }
}

const mapToProps = ({ step, data }) => ({
  step,
  data,
});
const connectedSecondStep = connect(mapToProps, actions)(SecondStep);
export { connectedSecondStep as SecondStep };
