// Components
import LoadingActions from './LoadingActions';
import AuthActions from './AuthActions';
import AuthActionsV2 from './AuthActionsV2';
import ErrorActions from './ErrorActions';

const actions = (store) => ({
  LoadingActions: (state, params) => LoadingActions.handler(store, params),
  AuthActions: (state, params) => AuthActions.handler(store, params),
  AuthActionsV2: (state, params) => AuthActionsV2.handler(store, params),
  ErrorActions: (state, params) => ErrorActions.handler(store, params),
  ErrorActions: (state, params) => ErrorActions.handler(store, params)
});

export default actions;
