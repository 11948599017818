// Library
import React from "react";
import { Row, Col, Image, Card } from "react-bootstrap";
import { Button } from "@mui/material";
import empty from "is-empty";
import { connect } from "redux-zero/react";
import actions from "../../actions";

// Components

// CSS & Img
import { FaSignOutAlt } from "react-icons/fa";

class Header extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: {},
    };
  }

  processSignOut() {
    this.props.AuthActionsV2({ action: "clearSessionV2" });
  }

  render() {
    const { user } = this.props;

    return (
      <>
        <Row className="header-container">
          <Card className="secondary-card">
            <Col xs={8} sm={9} className="logo-client-container">
              <Image
                src={`/assets/logos/${
                  user != null && !empty(user)
                    ? user.merchant_logo
                    : "gbc-logo.png"
                }`}
                alt="client_logo"
                width="160"
                height="auto"
                className="client-logo"
              />
            </Col>
            {user != null && !empty(user) && !!user.first_name ? (
              <Col xs={4} sm={3} className="text-center">
                <Button
                  variant="outlined"
                  className="btn-signout"
                  onClick={() => {
                    this.processSignOut();
                  }}
                >
                  <FaSignOutAlt className="copy-icon mr-1" />
                  <span className="btn-signout-text">Cerrar Sesión</span>
                </Button>
              </Col>
            ) : (
              ""
            )}
          </Card>
        </Row>
      </>
    );
  }
}

const mapToProps = ({ user }) => ({
  user,
});
const connectedHeader = connect(mapToProps, actions)(Header);
export { connectedHeader as Header };
