// Library
import React from 'react';
import { Row, Col, Container, Card } from 'react-bootstrap';
import empty from 'is-empty';
import { connect } from 'redux-zero/react';
import actions from '../../actions';

// Components
import TemplateCard from './Card';
import { DownloadApp } from './DownloadApp';

class SecondStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentWillMount(props) {
    const { data } = this.props;
  }

  render() {
    const { data } = this.props;
    let user,
      card,
      merchant,
      movimientos,
      soles,
      dolares = '';
    if (!empty(data)) {
      user = data.client;
      card = data.card;
      merchant = data.merchant;
      movimientos = data.transactions;
      soles = card.amount;
      dolares = Number(soles) * 0.25;
    }
    return (
      <>
        <Row className="secondstep-container">
          <div className="m-auto">
            <TemplateCard data={data} {...this.props} />
            <Container fluid className="balance-container">
              <Row className="justify-content-center">
                <Card style={{ width: '22rem' }}>
                  <Card.Body>
                    <Card.Subtitle className="mb-2 text-muted text-left">
                      <h4>Saldo Disponible</h4>
                    </Card.Subtitle>
                    <Card.Text>
                      <Row className="justify-content-center balance-amount">
                        <Col xs="4" className="d-flex">
                          <h6 className="mr-2">S/</h6>
                          <h5>{empty(data) ? 0.0 : Number(soles).toFixed(2).toString().replace('.', ',')}</h5>
                        </Col>
                        <Col xs="1">
                          <h6>=</h6>
                        </Col>
                        <Col xs="5" className="d-flex">
                          <h6 className="mr-2">USD$/</h6>
                          <h5>{empty(data) ? 0.0 : dolares.toFixed(2).toString().replace('.', ',')}</h5>
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                </Card>
              </Row>
            </Container>
            <Container fluid className="balance-container">
              <Row className="justify-content-md-center">
                <Card style={{ width: '22rem' }}>
                  <Card.Body>
                    <Row className="d-block container-last-tx">
                      <Card.Subtitle className="mb-2 text-muted text-left">
                        <h4>Últimos movimientos</h4>
                      </Card.Subtitle>
                      {empty(card) || !movimientos.length > 0 ? (
                        <>
                          <Card.Text>
                            <Row className="justify-content-center balance-amount">
                              <Col xs={12} className="field-icon">
                                <p>No posee transacciones disponibles</p>
                              </Col>
                            </Row>
                          </Card.Text>
                        </>
                      ) : (
                        movimientos.length > 0 &&
                        movimientos.map((m) => {
                          let amount = m.amount.includes('.')
                            ? m.amount.toString().replace('.', ',')
                            : Number(m.amount).toFixed(2).toString().replace('.', ',');

                          const color = `${m.transaction_type === 'CREDIT' ? '#61cf59' : '#c2544a'}`;
                          return (
                            <div key={m.hour}>
                              <Card.Text>
                                <div className="d-flex gap-2 justify-content-between align-items-center balance-amount">
                                  <div>
                                    <Row>
                                      <h6 className="mr-0">{m.date}</h6>
                                    </Row>
                                    <Row>
                                      <h6 className="mr-2 m-0">{m.commerce}</h6>
                                    </Row>
                                  </div>
                                  <div
                                    className="d-flex"
                                    style={{
                                      color
                                    }}
                                  >
                                    <p className="transactions-amount">
                                      {m.transaction_type === 'CREDIT' ? '+' : '-'} S/{amount}
                                    </p>
                                  </div>
                                </div>
                              </Card.Text>
                              <hr />
                            </div>
                          );
                        })
                      )}
                    </Row>
                  </Card.Body>
                </Card>
              </Row>
            </Container>
          </div>
          <DownloadApp />
        </Row>
      </>
    );
  }
}

const mapToProps = ({ step, data }) => ({
  step,
  data
});
const connectedSecondStep = connect(mapToProps, actions)(SecondStep);
export { connectedSecondStep as SecondStep };
