// Library
import React from 'react';
import { Row, Col, Card, Image } from 'react-bootstrap';
import { FaHandPaper } from 'react-icons/fa';
import empty from 'is-empty'
import { Loading } from 'react-loading-dot'
import clientGBC from "../assets/img/logo_gbc.png";
import clientBANEXCOIN from "../assets/img/logo_banex.png";
import clientELECTRON from "../assets/img/logo_electron.png";

class Welcome extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
          
        }
    }
    
    render() {
      const { showed } = this.state;
      const { welcome, user } = this.props
      let logoHeader = clientGBC;
      if(!empty(user)){

        if(user.gbcValeMerchantByGbcValeMerchantId.companyName == 'GLOBAL BRIDGE CONNECTIONS'){
          logoHeader = clientGBC;
        }

        if(user.gbcValeMerchantByGbcValeMerchantId.companyName == 'BANEXCOIN'){
          logoHeader = clientBANEXCOIN;
        }

        if(user.gbcValeMerchantByGbcValeMerchantId.companyName == 'ELECTRON MOTORS'){
          logoHeader = clientELECTRON;
        }
    }
      
      return (<>{!empty(user) ?
        <div className={`welcome-component ${!welcome ? 'show' : ''}`}>
          <Row className="justify-content-md-center component-centered">
            <Col xs={6}>
              <Card>
                  <Card.Body>
                    <Col xs={12} className="logo-client-container d-flex">
                      <Image src={logoHeader} alt="client_logo" width="160" height="auto" className="client-logo" />
                    </Col>
                    <Row className="d-flex">
                      <Col sm={12} md={1} className="d-flex justify-content-center mt-4">
                        <FaHandPaper className="hand-paper" />
                      </Col>
                      <Col sm={11} md={11}>
                        <Card.Subtitle className="mb-2 text-muted text-center">
                          ¡ Hola <b>{user.clientFname.split(' ')[0]}</b>, que gusto tenerte por aquí nuevamente !
                        </Card.Subtitle>
                      </Col>
                    </Row>
                    <div className="dots-spinner">
                      <Loading size=".5rem" margin=".5rem" background="rgb(18,62,89)"/>
                    </div>
                  </Card.Body>
              </Card>
            </Col>
          </Row>
        </div>
       : ''} </>);
    }
}


export default Welcome; 