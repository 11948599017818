// Library
import React from 'react';
import { Row, Col, Form, Container, Card, Button } from 'react-bootstrap';
import empty from 'is-empty';
import ReactCodeInput from 'react-verification-code-input';
import { connect } from 'redux-zero/react';
import queryString from 'query-string';

import actions from '../../actions';
import { DownloadApp } from './DownloadApp';

class FirstStep extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      data: {}
    };
  }

  render() {
    const { user, match } = this.props;
    return (
      <>
        {!empty(user) && !!user.email ? (
          <Container fluid className="firststep">
            <Row className="justify-content-md-center">
              <Form>
                <Card>
                  <Card.Body>
                    <Card.Subtitle className="mb-2 text-muted text-center">
                      Hemos enviado un código de verificación al correo electrónico{' '}
                      {user.email.replace(/^(.{3})[^@]+/, '$1***')}
                    </Card.Subtitle>
                    <Card.Text>
                      <Row className="justify-content-md-center input-code mt-4">
                        <Col xs="12" className="d-flex">
                          <ReactCodeInput
                            className={'confirmation-code-box'}
                            autoFocus={true}
                            fields={6}
                            onComplete={(code) => {
                              // traer info del localstorage
                              let params = {};
                              const gbcValeCardLocalStorageV2 = localStorage.getItem('gbcValeCardV2');
                              const clientData = JSON.parse(gbcValeCardLocalStorageV2);
                              if (gbcValeCardLocalStorageV2) {
                                params = {
                                  id: clientData.id,
                                  timestamp: clientData.timestamp,
                                  code
                                };
                              } else {
                                const id = match.params.id;
                                const queryData = queryString.parse(location.search);
                                const timestamp = parseInt(queryData.t);
                                params = {
                                  id,
                                  code,
                                  timestamp: String(timestamp)
                                };
                              }
                              this.props.AuthActionsV2({
                                action: 'confirmDataV2',
                                params: { params }
                              });
                            }}
                          />
                        </Col>
                      </Row>
                    </Card.Text>
                  </Card.Body>
                  <Row className="request-code">
                    <a
                      href="#"
                      onClick={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        // traer info del localstorage
                        let params = {};
                        const gbcValeCardLocalStorageV2 = localStorage.getItem('gbcValeCardV2');
                        const clientData = JSON.parse(gbcValeCardLocalStorageV2);
                        if (gbcValeCardLocalStorageV2) {
                          params = {
                            id: clientData.id,
                            timestamp: clientData.timestamp
                          };
                        } else {
                          const id = match.params.id;
                          const queryData = queryString.parse(location.search);
                          const timestamp = parseInt(queryData.t);
                          params = {
                            id,
                            timestamp: String(timestamp)
                          };
                        }
                        this.props.AuthActionsV2({
                          action: 'processCardV2',
                          params: { params }
                        });
                      }}
                    >
                      Solicitar de nuevo el código de verificación
                    </a>
                    <DownloadApp />
                  </Row>
                </Card>
              </Form>
            </Row>
          </Container>
        ) : (
          <div style={{ display: 'grid', placeItems: 'center', height: '100px' }}>
            <Button onClick={() => window.location.reload()}>Reintentar</Button>
          </div>
        )}
      </>
    );
  }
}

const mapToProps = ({ user, loading, loadingMsg, location }) => ({
  user,
  loading,
  loadingMsg,
  location
});
const connectedFirstStep = connect(mapToProps, actions)(FirstStep);
export { connectedFirstStep as FirstStep };
