// Library
import React from "react";
import { Row, Col, Card, Image } from "react-bootstrap";
import { FaHandPaper } from "react-icons/fa";
import empty from "is-empty";
import { Loading } from "react-loading-dot";

class Welcome extends React.Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const { welcome, user } = this.props;

    return (
      <>
        {!empty(user) ? (
          <div className={`welcome-component ${!welcome ? "show" : ""}`}>
            <Row className="justify-content-md-center component-centered">
              <Col xs={6}>
                <Card>
                  <Card.Body>
                    <Col xs={12} className="logo-client-container d-flex">
                      <Image
                        src={`/assets/logos/${
                          user != null && !empty(user)
                            ? user.merchant_logo
                            : "gbc-logo.png"
                        }`}
                        alt="client_logo"
                        width="160"
                        height="auto"
                        className="welcome-client-logo"
                      />
                    </Col>
                    <Row className="d-flex">
                      <Col
                        sm={12}
                        md={1}
                        className="d-flex justify-content-center mt-4"
                      >
                        <FaHandPaper className="hand-paper" />
                      </Col>
                      <Col sm={11} md={11}>
                        {user != null && !empty(user) && !!user.first_name ? (
                          <Card.Subtitle className="mb-2 text-muted text-center">
                            ¡ Hola <b>{user?.first_name.split(" ")[0]}</b>, que
                            gusto tenerte por aquí nuevamente !
                          </Card.Subtitle>
                        ) : null}
                      </Col>
                    </Row>
                    <div className="dots-spinner">
                      <Loading
                        size=".5rem"
                        margin=".5rem"
                        background="rgb(18,62,89)"
                      />
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          </div>
        ) : null}
      </>
    );
  }
}

export default Welcome;
