// Library
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'redux-zero/react';
import store from './helpers/Store';

// Components
import { App } from './App';

// CSS & Img
import 'bootstrap/dist/css/bootstrap.min.css';
import './assets/css/index.css';
import './assets/css/app.css';
import './assets/css/header.css';
import './assets/css/cards.css';
import './assets/css/firststep.css';
import './assets/css/secondstep.css';
import './assets/css/footer.css';
import './assets/css/loading.css';
import './assets/css/welcome.css';
import './assets/css/message.css';

import 'rsuite/dist/rsuite.min.css';

ReactDOM.render(
  <BrowserRouter>
    <Provider store={store}>
      <App />
    </Provider>
  </BrowserRouter>,
  document.getElementById('root')
);
