import React, {Component} from "react";

// Components Bootstrap & Material
import { Container, Row, Card, Col, Image, Modal } from 'react-bootstrap';
import { Switch } from '@mui/material';
import {FirstStep} from "./FirstStep"

// CSS Img & Icons
import visa from "../assets/img/visa.png";
import mastercard from "../assets/img/mastercard.png";
import { FaRegCopy } from 'react-icons/fa';
import empty from 'is-empty'

class TemplateCard extends Component {
    constructor(props){
    super(props);
    this.state = {
            show: false
        }
    }

    msgToastToCopyClipboard(){
        toast.success("Copiado al portapapeles", {
            position: "top-center",
            autoClose: 5000,
            closeOnClick: true,
            pauseOnHover: true,
        })
    }

    render(){
        const {show} = this.state
        const { data } = this.props;
        let client, card = {};

        if(!empty(data)){
            client = data.data.client;
            card = data.data.card;
        }
        
        return(
            <Container fluid className="card-container">
                <Row className="justify-content-md-center">
                    <Card style={{ width: '22rem' }}>
                        <Card.Body>
                            <Card.Subtitle className="mb-2 text-muted text-right">
                                <h4>Ver detalle CVV</h4>
                                <Switch
                                        checked={show}
                                        onChange={()=>{this.setState({show: !show})}}
                                        name="show"
                                        color="primary"
                                        inputProps={{ 'aria-label': 'primary checkbox' }}
                                    />
                            </Card.Subtitle>
                            <Card.Text>
                            <Row className="justify-content-md-center number-card">
                                <Col xs="8" className="d-flex">
                                    {!empty(data) && show ? <h4>{card.NUMERO_TARJETA.toString().match(/.{4}/g).join(' ')}</h4> : <h2 style={{color:'white', fontWeight:500}}>**** **** **** ****</h2>}{!empty(data) && show ? <FaRegCopy className="copy-icon" onClick={()=>
                                    {
                                        navigator.clipboard.writeText(card.NUMERO_TARJETA.toString());
                                        this.msgToastToCopyClipboard();
                                    }} /> : ""}
                                </Col>
                                <Col xs="4" className="d-flex">
                                    <h4>CVV {!empty(data) && show ? card.CVV2_VALUE : "***"}</h4>
                                    {!empty(data) && show ? <FaRegCopy className="copy-icon" onClick={()=>{
                                        navigator.clipboard.writeText(card.CVV2_VALUE);
                                        this.msgToastToCopyClipboard();
                                    }}
                                    /> : ""}
                                </Col>
                            </Row>
                            <Row className="top-info-card">
                                <Col xs="5" className="d-flex">
                                    <h4>Card Holder</h4> {!empty(data) ? <FaRegCopy className="copy-icon" onClick={()=>{
                                        navigator.clipboard.writeText(client.clientFname.split(' ')[0] + ' ' + client.clientLname.split(' ')[0]);
                                        this.msgToastToCopyClipboard();
                                    }}/> :''}
                                </Col>
                                <Col xs="5" className="d-flex">
                                    <h4>Expira</h4>{!empty(data) && show ? <FaRegCopy className="copy-icon" onClick={()=>{
                                        navigator.clipboard.writeText(card.FECHA_VENCIMIENTO.toString().match(/.{2}/g).join(' ').split(' ')[1] + '/20'+card.FECHA_VENCIMIENTO.toString().match(/.{2}/g).join(' ').split(' ')[0]);
                                        this.msgToastToCopyClipboard();
                                    }}/> : ""}
                                </Col>
                            </Row>
                            <Row className="info-card">
                                <Col xs="5">
                                    <h4>{!empty(data) ? client.clientFname.split(' ')[0] : ''} {!empty(data) ? client.clientLname.split(' ')[0]: ''}</h4>
                                </Col>
                                <Col xs="4">
                                    {!empty(data) && show ? <h4>{card.FECHA_VENCIMIENTO.toString().match(/.{2}/g).join(' ').split(' ')[1]} / 20{card.FECHA_VENCIMIENTO.toString().match(/.{2}/g).join(' ').split(' ')[0]}</h4> : <h4 style={{color:'white', fontWeight:500}}>** / ****</h4>}
                                </Col>
                                <Col xs="3">
                                    <Image src={mastercard} alt="card_logo" width="50" height="35" className="card-logo mr-4" />
                                </Col>
                            </Row>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                </Row>
            </Container>
        );
    }
}

export default TemplateCard;