// Library
import React from 'react';
import { Row, Form, Container, Card } from 'react-bootstrap';
import { DownloadApp } from './v2/DownloadApp';

class Home extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      alert: false
    };
  }

  render() {
    return (
      <>
        <Container fluid className="Home">
          <Row className="justify-content-md-center">
            <Form>
              <Card>
                <Card.Body>
                  <Card.Subtitle className="mb-2 text-muted text-center">
                    Este sitio solo se puede acceder por invitación..
                  </Card.Subtitle>
                </Card.Body>
              </Card>
            </Form>
          </Row>

          <DownloadApp />
        </Container>
      </>
    );
  }
}

export default Home;
