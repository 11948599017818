// Library
import React from 'react';
import { Row, Alert } from 'react-bootstrap';
import empty from 'is-empty';
import queryString from 'query-string';
import { connect } from 'redux-zero/react';
import { isMobile } from 'react-device-detect';
import Modal from 'rsuite/Modal';
import actions from '../actions';

// Components
import { FirstStep } from '../components/v2/FirstStep';
import { SecondStep } from '../components/v2/SecondStep';
import isEmpty from 'is-empty';
import { DownloadApp } from '../components/v2/DownloadApp';

class Main2 extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      client: {},
      card: {},
      movimientos: [],
      soles: 0,
      dolares: 0,
      isMobile: false
    };
  }

  componentWillMount(props) {
    const { location, match } = this.props;

    if (!empty(location)) {
      const id = match.params.id;
      const queryData = queryString.parse(location.search);
      const timestamp = parseInt(queryData.t);
      const params = { id, timestamp: String(timestamp) };

      if (!isMobile) {
        this.props.AuthActionsV2({ action: 'processCardV2', params: { params } });
      } else {
        this.setState({ isMobile: true });
        if (params.id && params.timestamp) {
          window.location.replace(`valegbcpe://vale.gbc.pe/v2/card/validate/${params.id}?t=${params.timestamp}`);
          setTimeout(function () {
            console.log('App not installed..');
          }, 2000);
        }
      }
    }
  }

  render() {
    const { step } = this.props;
    let message = this.props.store.getState().message !== '' && this.props.store.getState().message;
    let data = !isEmpty(this.props.store.getState().data) && this.props.store.getState().data;
    console.log(this.state);
    return (
      <>
        {!this.state.isMobile ? (
          <>
            {message ? (
              <div className="message-wrapper">
                <Alert variant="info" style={{ textAlign: 'center' }}>
                  <h5 className="message-text">
                    {message}{' '}
                    <a
                      href={`mailto:soporte@globalbridgeconnections.com?subject=[VALE]: ${data.company_name} - ${data.first_name} ${data.first_last_name} - CAMPAÑA:${data.campaign_id}`}
                    >
                      soporte@globalbridgeconnections.com
                    </a>{' '}
                  </h5>
                </Alert>
                <DownloadApp />
              </div>
            ) : (
              <Row>
                {step === 0 ? <FirstStep {...this.props} /> : ''}
                {step === 1 ? <SecondStep {...this.props} /> : ''}
              </Row>
            )}
          </>
        ) : (
          <>
            <Modal size="xs" open>
              <Modal.Header closeButton={false}>
                <Modal.Title>Aplicación GBC Vale</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                Descarga la aplicación GBC Vale ahora y disfruta de: <br />
                <br />
                <ul>
                  <li>Acceso fácil y rápido a visualizar tus consumos.</li>
                  <li>Interfaz intuitiva y fácil de usar.</li>
                  <li>Actualizaciones regulares con nuevas funcionalidades y mejoras.</li>
                </ul>
                <DownloadApp />
              </Modal.Body>
            </Modal>
          </>
        )}
      </>
    );
  }
}

const mapToProps = ({ step, data }) => ({
  step,
  data
});
const connectedMain = connect(mapToProps, actions)(Main2);
export { connectedMain as Main2 };
