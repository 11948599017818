// Library
import React from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import { history } from "./helpers/History";
import { Container } from "react-bootstrap";
import { connect } from "redux-zero/react";
import actions from "./actions";
import { ToastContainer, toast } from "react-toastify";

// Components
import { Main } from "./container/Main";
import { Main2 } from "./container/Main2";
import { Header } from "./components/Header";
import { Header as HeaderV2 } from "./components/v2/Header";
import Footer from "./components/Footer";
import Welcome from "./components/Welcome";
import WelcomeV2 from "./components/v2/Welcome";
import Home from "./components/Home";
import Loading from "./components/Loading";

// CSS
import "react-toastify/dist/ReactToastify.css";

class App extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      welcome: false,
    };
    this.timerId = null;
  }

  componentDidMount() {}

  componentDidUpdate(prevProps) {
    if (this.props.user != prevProps.user) {
      this.setState({
        welcome: true,
      });
      this.hideWelcomeScreen();
    }
  }

  hideWelcomeScreen() {
    setTimeout(() => {
      this.setState({ welcome: false });
      this.timerId = null;
    }, 4000);
  }

  render = () => {
    const { loading, user, status } = this.props;
    const { welcome } = this.state;
    return (
      <Router history={history}>
        <Container>
          <ToastContainer />
          {loading == 1 ? (
            <Loading ini={0} interval={10} timing={100} {...this.props} />
          ) : null}
          {history.location.pathname.toString().includes("/v2") ? (
            <>
              <HeaderV2 {...this.props} />
              <WelcomeV2 {...this.props} welcome={welcome} />
            </>
          ) : (
            <>
              {user && <Header {...this.props} />}
              <Welcome {...this.props} welcome={welcome} />
            </>
          )}
          <Switch>
            <Route
              exact
              path="/card/validate/:id"
              {...this.props}
              component={Main}
            />
            <Route
              exact
              path="/v2/card/validate/:id"
              {...this.props}
              component={Main2}
            />
            <Route path="/" {...this.props} component={Home} />
          </Switch>
          <Footer />
        </Container>
      </Router>
    );
  };
}
const mapToProps = ({ user, loading, loadingMsg, location, status }) => ({
  user,
  loading,
  loadingMsg,
  location,
  status,
});
const connectedApp = connect(mapToProps, actions)(App);
export { connectedApp as App };
