import HandlerBackend from "../helpers/HandlerBackend";

class AuthService {
  static async processCard({ params } = { params }) {
    try {
      const request = await HandlerBackend.sendRequest({
        method: "post",
        path: "/client/card/validate",
        data: params,
      });
      return request.data;
    } catch (error) {
      throw error.response;
    }
  }

  static async confirmData({ params } = { params }) {
    try {
      const request = await HandlerBackend.sendRequest({
        method: "post",
        path: "/client/card/validate/confirm",
        data: params,
      });
      return request.data;
    } catch (error) {
      throw error.response;
    }
  }

  static async processCardV2(params) {
    try {
      const request = await HandlerBackend.sendRequestV2({
        method: "post",
        path: "/client-cards/validate",
        data: params,
      });
      return request.data;
    } catch (error) {
      throw error.response;
    }
  }

  static async confirmDataV2(params) {
    try {
      const { id, ...otherParams } = params;
      const request = await HandlerBackend.sendRequestV2({
        method: "post",
        path: `/client-cards/validate/${id}/confirm`,
        data: otherParams,
      });
      return request.data;
    } catch (error) {
      throw error.response;
    }
  }
}

export default AuthService;
