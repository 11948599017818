import LoadingActions from "./LoadingActions";
import AuthService from "../services/AuthService";
import { history } from "../helpers/History";
import ErrorActions from "./ErrorActions";
import { toast } from "react-toastify";
import empty from "is-empty";
class AuthActionsV2 {
  static redirect(key) {
    let route = "";

    if (key == "EMPTY") {
      route = "/";
    }

    if (route != "") {
      history.push(route);
      window.location.reload(true);
    }
  }

  static handler(store, params) {
    return new AuthActionsV2[params.action](store, params.params);
  }

  static clearSessionV2(store) {
    store.setState({
      loading: LoadingActions.show(store),
    });

    let redirect = "EMPTY";

    localStorage.removeItem("gbcValeCardV2");

    store.setState({
      loading: 0,
      loadingMsg: "",
      user: {},
      data: {},
      step: 0,
      status: "logout",
      location: "",
      loading: LoadingActions.hide(store),
    });

    toast.success("Sesión finalizada con éxito", {
      position: toast.POSITION.TOP_CENTER,
    });

    AuthActionsV2.redirect(redirect);
  }

  static async processCardV2(store, { params } = { params }) {
    let data = {};
    let status = "";

    store.setState({
      loading: LoadingActions.show(store),
    });

    try {
      let request = await AuthService.processCardV2(params);
      data = request.data;
      status = "Verified";
      localStorage.setItem(
        "gbcValeCardV2",
        JSON.stringify({ id: data.card.id, timestamp: params.timestamp })
      );

      store.setState({
        user: {
          ...data.client,
          merchant_logo: !empty(data)
            ? data.merchant.company_logo
            : "gbc-logo.png",
        },
        data,
        status,
      });
    } catch (error) {
      if (
        (error.data.data && error.data.data.status === "BLOCKED") ||
        "SUSPENDED" ||
        "INACTIVE" ||
        "CANCELED" ||
        "PENDING"
      ) {
        ErrorActions.handlerErrorMessage(store, error);
      } else {
        ErrorActions.handlerError(store, error);
      }
    } finally {
      store.setState({
        loading: LoadingActions.hide(store),
      });
    }
  }

  static async confirmDataV2(store, { params } = { params }) {
    let data = {};
    let step = 0;

    store.setState({
      loading: LoadingActions.show(store),
    });

    try {
      const response = await AuthService.confirmDataV2(params);
      step = 1;
      data = response.data;
      const { timestamp, code, type } = params;
      localStorage.setItem(
        "gbcValeCardV2",
        JSON.stringify({
          id: data.card.id,
          timestamp,
          code,
          type,
        })
      );
    } catch (error) {
      ErrorActions.handlerError(store, error);
    }

    store.setState({
      data,
      user: {
        ...data.client,
        merchant_logo: !empty(data)
          ? data.merchant.company_logo
          : "gbc-logo.png",
      },
      step,
      loading: LoadingActions.hide(store),
    });
  }
}

export default AuthActionsV2;
